import { GenerateReportTypeEnum } from "@/interfaces/reports/v2/report";
import { ReportSectionTab, ReportSectionTabIcon } from "./Model";
import { ActivePanelTab, SectionTabType } from "@/interfaces/report";

/**
 * Definición de paneles comunes para evitar la repetición de código
 */
const createReportSectionTab = (
  id: number,
  key: SectionTabType,
  title: string,
  required: boolean = false,
  expanded: boolean = true,
  showFilter: boolean = false,
  showClearFilter: boolean = false,
  icon?: ReportSectionTabIcon,
): ReportSectionTab => {
  return new ReportSectionTab({
    id,
    key,
    title,
    required,
    expanded,
    showFilter,
    showClearFilter,
    icon,
  });
};

/**
 * Paneles por defecto
 */
export const defaultReportPanels: ReportSectionTab[] = [
  createReportSectionTab(0, "report_type", "report.report_type", false, true),
  createReportSectionTab(
    1,
    "filters",
    "report.title.Filters",
    true,
    true,
    true,
    true,
    new ReportSectionTabIcon({
      filter: { value: "mdi-arrow-expand", label: "report.title.Filters" },
      clearFilter: { value: "mdi-filter-remove", label: "report.title.remove_Filters" },
    }),
  ),
  createReportSectionTab(2, "dimensions", "report.title.dimension", true),
  createReportSectionTab(3, "metrics", "report.title.metrics", true),
];

/**
 * Paneles de `Reporte Performance`
 */
export const performanceReportPanels: ReportSectionTab[] = [
  createReportSectionTab(0, "report_type", "report.report_type", false, true),
  createReportSectionTab(1, "looker_url", "report.title.campaign_performance"),
];

/**
 * Paneles de `Report Partial`
 */
export const partialReportPanels: ReportSectionTab[] = [
  createReportSectionTab(0, "report_type", "report.report_type", false, true),
  createReportSectionTab(1, "looker_url", "report.title.partial_report"),
];

/**
 * Tipo de mapeo para los paneles por tipo de reporte
 */
type PanelMap = {
  [key in GenerateReportTypeEnum]?: ReportSectionTab[];
};

/**
 * Obtiene los paneles por tipo de reporte
 * @param reportType Tipo de reporte
 * @returns Paneles por tipo de reporte
 */
export function getPanelsByReportType(reportType: GenerateReportTypeEnum): ReportSectionTab[] {
  const panelsMap: PanelMap = {
    [GenerateReportTypeEnum.PERFORMACE_CAMPAIGN_SP]: performanceReportPanels,
    [GenerateReportTypeEnum.PARTIAL_REPORT_SP]: partialReportPanels,
  };

  return panelsMap[reportType] || defaultReportPanels;
}

/**
 * Obtiene las pestañas activas para un tipo de reporte
 * @param reportType Tipo de reporte
 * @returns Pestañas activas para ese reporte
 */
export function getPanelActiveByReportType(reportType: GenerateReportTypeEnum): ActivePanelTab[] {
  const activePanelsMap = {
    [GenerateReportTypeEnum.PERFORMACE_CAMPAIGN_SP]: [ActivePanelTab.REPORT_TYPE, ActivePanelTab.CAMPAIGN_PERFORMANCE],
    [GenerateReportTypeEnum.PARTIAL_REPORT_SP]: [ActivePanelTab.REPORT_TYPE, ActivePanelTab.CAMPAIGN_PARTIAL],
  };

  const defaultActivePanels = [
    ActivePanelTab.REPORT_TYPE,
    ActivePanelTab.FILTERS,
    ActivePanelTab.DIMENSIONS,
    ActivePanelTab.METRICS,
  ];

  return activePanelsMap[reportType] || defaultActivePanels;
}
