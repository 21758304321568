import { DATA_RANGE_CUSTOM, DATA_RANGE_LAST_7_DAYS } from "@/utils/report";
import { GenerateReportType, GenerateReportTypeEnum } from "./reports/v2/report";
import { getDateByYear, getDateRangeWithMonthsAgo } from "@/services/date-service";
import { getPanelActiveByReportType } from "@/models/Reports/v2/ReportPanels/Data";
import { CampaignList } from "@/models/Reports/v2/Report";

export enum ScheduleStatus {
  PENDING = "pending",
  FAIL = "fail",
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum SectionType {
  REPORT_TYPE = "report_type",
  FILTERS = "filters",
  DIMENSIONS = "dimensions",
  METRICS = "metrics",
  LOOKER_URL = "looker_url",
}

export type SectionTabType = keyof typeof SectionType extends infer U
  ? U extends string
    ? Lowercase<U>
    : never
  : never;

export interface ReportFilters {
  advertiser_id: string;
  campaign_id: string;
  line_item_id: string;
  creative_id: string;
}

export interface ReportFiltersSP {
  account_id: number[];
  advertiser_id: number[];
  campaign_id: number[];
  line_item_id: number[];
  creative_id: number[];
  deal_id: number[];
  layer_name?: number[];
  layer_id_filter?: number[];
  ooh_layer_name?: number[];
  layer_id_ooh_filter?: number[];
  ooh_distance?: number;
}

export interface FilterData {
  advertiser_id: string;
  campaign_id: string;
  line_item_id: string;
  creative_id: string;
  account_id: number[];
  start_date: string;
  end_date: string;
  data_range: string;
}

export interface DataItem {
  id: string;
  value: string;
}

export interface DataItemName {
  id: string;
  name: string;
}

export interface ResourceData {
  report_type: DataItem[];
  data_range: DataItem[];
  type: DataItem[];
  filteredDimensions: DataItem[];
  filteredMetrics: DataItem[];
  account: FilterProperty[];
  advertisers: FilterProperty[];
  campaigns: FilterProperty[];
  line_items: FilterProperty[];
  creatives: FilterProperty[];
  deal_id: FilterProperty[];
  layer_name: FilterProperty[];
  ooh_layer_name: FilterProperty[];
  ooh_distance: FilterProperty[];
  report_dimensions?: ResourceDimension;
  report_metrics: DataItemName[];
  report_all_type: DataItem[];
  scheduler_type: DataItem[];
  dimensions: Record<string, number>;
  metrics: Record<string, number>;
  dimension: DataItem[];
  metric: DataItem[];
}

export interface ResourceDimension {
  account: DataItem[];
  dates: DataItem[];
  advertiser: DataItem[];
  campaign: DataItem[];
  line_item: DataItem[];
  creative: DataItem[];
  performance: DataItem[];
}

export interface ReportRun {
  report_name: string;
  data_range: string;
  type: string;
  dimensions: Array<string>;
  metrics: Array<string>;
  filters: ReportFilters;
}

export interface ReportFilterSP {
  data_range: string;
  start_date?: string;
  end_date?: string;
  report_type: string;
  year: number;
  filters: ReportFiltersSP;
}

export interface GeneratedReport {
  data_range: string;
  metrics: Object;
  filters: ReportFiltersSP;
  report_type: GenerateReportType;
  start_date?: string;
  end_date?: string;
  type: EnumReportType;
  year: number;
}

export interface ResponseJob {
  no_data: boolean;
  report_job_id?: number;
}

export interface SchedulerReportFilters {
  email_subject: string;
  scheduler_type: string;
  start_date: string;
  end_date: string;
  status: string;
  user_id: string;
}
export interface SchedulerReportDataUpdate {
  id: number;
  email_subject: string;
  scheduler_type: string;
  data_range: string;
  start_date: string;
  end_date: string;
  status: string;
  user_id: string;
  dimensions: Array<string>;
  metrics: Array<string>;
  filters: ReportFilters;
  emails: String;
  schuedule_start_date: string;
  schuedule_end_date: string;
  include_account_email: boolean;
}
export enum EnumStatus {
  ERROR = "error",
  PENDING = "in_progress",
  SUCCESS = "success",
  EMPTY = "empty",
}

export enum EnumReportType {
  CSV = "csv",
  XLS = "xlsx",
  NO_TYPE = "",
}

export enum DownloadType {
  csv = "text/csv",
  xls = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  plain = "text/plain",
}

export interface ReportStatus {
  id?: number;
  status: EnumStatus;
}

export interface ReportGetParam {
  id: number;
  type: EnumReportType.CSV | EnumReportType.CSV;
}

export interface ResultData<T = ReportStatus | string> {
  success: Boolean;
  response?: T;
  message: string;
}

export class ResultDataEntity implements ResultData {
  success: Boolean = false;
  response?: ReportStatus | string;
  message: string = "";

  constructor(params?: ResultData) {
    if (params) {
      this.success = params.success;
      this.response = params.response;
      this.message = params.message;
    }
  }
}

export class ResourceDimensionEntity implements ResourceDimension {
  account: DataItem[] = [];
  dates: DataItem[] = [];
  advertiser: DataItem[] = [];
  campaign: DataItem[] = [];
  line_item: DataItem[] = [];
  creative: DataItem[] = [];
  performance: DataItem[] = [];

  constructor() {}
}

export class ResourceDataEntity implements ResourceData {
  report_type: DataItem[] = [];
  data_range: DataItem[] = [];
  type: DataItem[] = [];
  dimension: DataItem[] = [];
  metric: DataItem[] = [];
  filteredDimensions: DataItem[] = [];
  filteredMetrics: DataItem[] = [];
  account: FilterProperty[] = [];
  advertisers: FilterProperty[] = [];
  campaigns: FilterProperty[] = [];
  line_items: FilterProperty[] = [];
  creatives: FilterProperty[] = [];
  deal_id: FilterProperty[] = [];
  layer_name: FilterProperty[] = [];
  ooh_layer_name: FilterProperty[] = [];
  ooh_distance: FilterProperty[] = [];
  report_dimensions?: ResourceDimension;
  report_metrics: DataItemName[] = [];
  report_all_type: DataItem[] = [];
  scheduler_type: DataItem[] = [];
  dimensions: Record<string, number> = {};
  metrics: Record<string, number> = {};

  constructor(resources?: ResourceData) {
    this.report_type = resources?.report_type || [];
    this.data_range = resources?.data_range || [];
    this.type = resources?.type || [];
    this.dimension = resources?.dimension || [];
    this.metric = resources?.metric || [];
    this.filteredDimensions = resources?.filteredDimensions || [];
    this.filteredMetrics = resources?.filteredMetrics || [];
    this.account = resources?.account || [];
    this.advertisers = resources?.advertisers || [];
    this.campaigns = resources?.campaigns || [];
    this.line_items = resources?.line_items || [];
    this.creatives = resources?.creatives || [];
    this.deal_id = resources?.deal_id || [];
    this.layer_name = resources?.layer_name || [];
    this.ooh_layer_name = resources?.ooh_layer_name || [];
    this.ooh_distance = resources?.ooh_distance || [];
    this.report_dimensions = resources?.report_dimensions;
    this.report_metrics = resources?.report_metrics || [];
    this.report_all_type = resources?.report_all_type || [];
    this.scheduler_type = resources?.scheduler_type || [];
    this.dimensions = resources?.dimensions || {};
    this.metrics = resources?.metrics || {};
  }

  async setData<T>(property: string, value: T) {
    this[property] = value;
  }

  resetFilters() {
    this.setData("advertisers", []);
    this.setData("campaigns", []);
    this.setData("creatives", []);
    this.setData("line_items", []);
    this.setData("deal_id", []);
    this.setData("layer_name", null);
    this.setData("ooh_layer_name", null);
    this.setData("ooh_distance", null);
  }

  resetDmFilters() {
    this.setData("report_metrics", []);
    this.setData("report_dimensions", []);
    this.setData("dimensions", {});
    this.setData("metrics", {});
  }
}

export interface Filters {
  advertisers: FilterProperty[];
  campaigns: FilterProperty[];
  line_items: FilterProperty[];
  creatives: FilterProperty[];
  account: FilterProperty[];
  deal_id: FilterProperty[];
  layer_name: FilterProperty | null;
  ooh_layer_name: FilterProperty | null;
  ooh_distance: FilterProperty | null;
}

export type FiltersType = {
  [K in keyof Filters]: FilterProperty[];
};

export interface ReportData {
  report_type: string;
  report_year: number;
  start_date: string;
  end_date: string;
  data_range: string;
  type: string;
  dimension: DataItem[];
  metric: DataItem[];
  dimensions: Record<string, number>;
  metrics: Record<string, number>;
  filters: FiltersEntity;
  campaign?: any;
}

export interface FilterProperty {
  key: string;
  type: string;
  value: string;
}

export class FiltersEntity implements Filters {
  advertisers: FilterProperty[] = [];
  campaigns: FilterProperty[] = [];
  line_items: FilterProperty[] = [];
  creatives: FilterProperty[] = [];
  account: FilterProperty[] = [];
  deal_id: FilterProperty[] = [];
  layer_name: FilterProperty | null = null;
  ooh_layer_name: FilterProperty | null = null;
  ooh_distance: FilterProperty | null = null;

  constructor(account?: FilterProperty[]) {
    if (account) {
      this.account = account;
    }
  }

  async setData(filters: Partial<Filters>) {
    if (filters?.account) {
      this.account = filters?.account || [];
    }
    this.advertisers = filters?.advertisers || [];
    this.campaigns = filters?.campaigns || [];
    this.line_items = filters?.line_items || [];
    this.creatives = filters?.creatives || [];
    this.deal_id = filters?.deal_id || [];
    this.layer_name = filters?.layer_name || null;
    this.ooh_layer_name = filters?.ooh_layer_name || null;
    this.ooh_distance = filters?.ooh_distance || null;
  }

  /**
   * Convierte las propiedades del filtro en un registro de claves.
   */
  convertToKeys(): Record<keyof Filters, (number | string)[] | number | null> {
    return {
      advertisers: this.mapKeys("advertisers"),
      campaigns: this.mapKeys("campaigns"),
      line_items: this.mapKeys("line_items"),
      creatives: this.mapKeys("creatives"),
      account: this.mapKeys("account"),
      deal_id: this.mapKeys("deal_id"),
      layer_name: this.mapKeys("layer_name"),
      ooh_layer_name: this.mapKeys("ooh_layer_name"),
      ooh_distance: this.mapKeys("ooh_distance"),
    };
  }

  /**
   * Mapea las propiedades del filtro a un arreglo de claves.
   */
  private mapKeys<T extends keyof Filters>(type: T): (number | string)[] | number | null {
    const filter: FilterProperty | FilterProperty[] | null | undefined = this[type];

    if (!filter) {
      return [];
    }

    if (!Array.isArray(filter)) {
      // It's a single FilterProperty
      return filter.key ? Number(filter.key) : null;
    }

    // It's an array of FilterProperty
    return filter.map(x => {
      if (["deal_id"].includes(type)) return String(x.key);
      return Number(x.key);
    });
  }
}

export class ReportDataEntity implements ReportData {
  report_type: GenerateReportTypeEnum = GenerateReportTypeEnum.CAMPAIGN_REPORT_SP;
  report_year: number = new Date().getFullYear();
  start_date: string = "";
  end_date: string = "";
  data_range: string = "";
  type: EnumReportType = EnumReportType.NO_TYPE;
  dimension: DataItem[] = [];
  metric: DataItem[] = [];
  dimensions: Record<string, number> = {};
  metrics: Record<string, number> = {};
  filters: FiltersEntity = new FiltersEntity();

  campaign?: CampaignList;

  constructor(dataParams?: any) {
    this.report_type = dataParams?.report_type || GenerateReportTypeEnum.CAMPAIGN_REPORT_SP;
    this.start_date = dataParams?.start_date || "";
    this.end_date = dataParams?.end_date || "";
    this.type = dataParams?.type || "";

    if (dataParams?.dimensions) {
      this.dimensions = dataParams.dimensions;
    }

    if (dataParams?.metrics) {
      this.metrics = dataParams.metrics;
    }

    this.campaign = dataParams?.campaign ? new CampaignList(dataParams.campaign) : undefined;
  }

  async setReportData(report?: any) {
    this.report_type = report?.report_type || GenerateReportTypeEnum.CAMPAIGN_REPORT_SP;
    this.start_date = report?.start_date || "";
    this.end_date = report?.end_date || "";
    this.data_range = report?.data_range || DATA_RANGE_LAST_7_DAYS;
    this.type = report?.type || EnumReportType.CSV;
    this.dimensions = report?.dimensions || {};
    this.metrics = report?.metrics || {};
    this.filters = report?.filters || new FiltersEntity();
    this.report_year =
      report?.data_range === DATA_RANGE_CUSTOM ? new Date(this.start_date).getFullYear() : new Date().getFullYear();
  }

  async setDimensionMetric(payload: { dimensions: Record<string, number>; metrics: Record<string, number> }) {
    this.dimensions = payload.dimensions;
    this.metrics = payload.metrics;
  }

  async setFilters(filters: FiltersEntity) {
    this.filters = filters;
  }

  async setData<T>(property: string, value: T) {
    this[property] = value;
  }

  async setDateData(params: { start_date: string; end_date: string }) {
    this.setData("start_date", params.start_date);
    this.setData("end_date", params.end_date);
  }

  LOGGIN_DEBUG(from: string) {
    console.debug(
      `[${from}] report_type: ${this.report_type} - report_year: ${this.report_year} - data_range: ${this.data_range}`,
    );
  }

  getActivePanelsByReportType() {
    return getPanelActiveByReportType(this.report_type as GenerateReportTypeEnum);
  }

  /**
	 * Watcher para el report_type


	 */
  async onChangeReportType() {
    //this.LOGGIN_DEBUG("ReportDataEntity::onChangeReportType");
    this.setCurrentYear();

    if (this.isOoh()) {
      this.setCustomLast2Month();
      return;
    }

    if (this.isStoreAttributions()) {
      this.setCustomFullYear();
      return;
    }

    await this.setLast7Days();
  }

  /**
   * Watcher para el report_year
   */
  async onChangeReportYear() {
    //this.LOGGIN_DEBUG("ReportDataEntity::onChangeReportYear");

    this.onChangeYear();

    if (this.isOoh()) {
      this.setCustomLast2Month();
      return;
    }

    if (this.isPreviusYear()) {
      this.setCustomFullYear();
      return;
    }

    this.setLast7Days();
  }

  /**
   * Watcher para el data_range
   */
  async onChangeReportDataRange() {
    //this.LOGGIN_DEBUG("ReportDataEntity::onChangeDataRange");

    if (this.isCustom()) {
      this.setCustomFullYear();
      return;
    }

    this.setDateData({ end_date: "", start_date: "" });
  }

  /**
   * Función genérica para establecer el rango de fechas y los datos
   * @param dataRange
   * @param dateData
   */
  async setDateRange(
    dataRange: string,
    dateData: {
      start_date: string;
      end_date: string;
    } = { start_date: "", end_date: "" },
  ) {
    this.setData("data_range", dataRange);
    this.setDateData(dateData);
  }

  /**
   * Establece el rango de fechas de los últimos 2 meses
   */
  async setCustomLast2Month() {
    this.setDateRange(DATA_RANGE_CUSTOM, getDateRangeWithMonthsAgo(2));
  }

  /**
   * Establece el rango de fechas de los últimos 7 días
   */

  async setLast7Days() {
    await this.setDateRange(DATA_RANGE_LAST_7_DAYS);
  }

  /**
   * Establece el rango de fechas del año seleccionado
   */
  async setCustomFullYear() {
    this.setDateRange(DATA_RANGE_CUSTOM, {
      start_date: getDateByYear("start_date", this.report_year),
      end_date: getDateByYear("end_date", this.report_year),
    });
  }

  /**
   * Resetea los datos de las dimensiones y métricas y filtros
   */
  onChangeYear() {
    this.setData("dimensions", {});
    this.setData("metrics", {});
    this.resetFilters();
  }

  /**
   * Resetea los filtros manteniendo el de account
   */
  resetFilters() {
    const account = this.filters.account;
    this.setData("filters", new FiltersEntity(account));
  }

  async setFilterData<T>(property: string, value: T) {
    this.filters[property] = value;
  }

  removeFilter(property: keyof ReportData, key: string | number) {
    const filter: FilterProperty[] = this.filters[property];
    this.filters[property] = filter.filter(f => f.key !== key);
  }

  isPreviusYear() {
    return this.report_year < new Date().getFullYear();
  }

  setCurrentYear() {
    this.report_year = new Date().getFullYear();
  }

  isCustom() {
    return this.data_range === DATA_RANGE_CUSTOM;
  }

  isLast7Days() {
    return this.data_range === DATA_RANGE_LAST_7_DAYS;
  }

  isOtherDataRange() {
    return !this.isCustom() && !this.isLast7Days();
  }

  isReportCampaign() {
    return this.report_type === GenerateReportTypeEnum.CAMPAIGN_REPORT_SP;
  }

  isReportGeography() {
    return this.report_type === GenerateReportTypeEnum.GEOGRAPHY_REPORT_SP;
  }

  isSelected(type: EnumReportType.CSV | EnumReportType.XLS) {
    return this.type === type;
  }

  isBilling() {
    return this.report_type === GenerateReportTypeEnum.BILLING_REPORT_SP;
  }

  isReach() {
    return this.report_type === GenerateReportTypeEnum.REACH_REPORT_SP;
  }

  isPerformance() {
    return this.report_type === GenerateReportTypeEnum.PERFORMACE_CAMPAIGN_SP;
  }

  isMaids() {
    return this.report_type === GenerateReportTypeEnum.MAIDS_REPORT_SP;
  }

  isStoreAttributions() {
    return this.report_type === GenerateReportTypeEnum.STORE_ATRIBUTTION_REPORT_SP;
  }

  isOoh() {
    return this.report_type === GenerateReportTypeEnum.OOH_REPORT_SP;
  }

  isGeoCoordinates() {
    return this.report_type === GenerateReportTypeEnum.GEO_COORDINATES_REPORT_SP;
  }

  isInsigths() {
    return this.report_type === GenerateReportTypeEnum.INSIGTHS_REPORT_SP;
  }

  isPartial() {
    return this.report_type === GenerateReportTypeEnum.PARTIAL_REPORT_SP;
  }

  hasSelectedCampaign() {
    return !!this.campaign && Object.keys(this.campaign).length > 0;
  }

  /**
   * Verifica si se debe obtener la URL de Looker
   * @returns
   */
  itShouldGetLookerURL() {
    // Reportes que deben obtener la URL de Looker
    const reportTypeShouldGetLookerURL = [
      GenerateReportTypeEnum.PERFORMACE_CAMPAIGN_SP,
      GenerateReportTypeEnum.PARTIAL_REPORT_SP,
    ];

    return reportTypeShouldGetLookerURL.includes(this.report_type as GenerateReportTypeEnum);
  }
}

export enum ActivePanelTab {
  NONE = NaN,
  REPORT_TYPE = 0,
  FILTERS = 1,
  DIMENSIONS = 2,
  METRICS = 3,
  SAVE_SCHEDULE = 4,
  CAMPAIGN_PERFORMANCE = 1,
  CAMPAIGN_PARTIAL = 1,
}

export interface DataDisplayItem {
  key: string;
  title: string;
  value?: number | string | null;
  items?: any;
  option?: boolean;
  isParent?: boolean;
}

export interface ReportResourceData {
  selected_all: ReportResourceDataAll;
  hidden_fields: ReportResourceDataHidden;
  loading: ReportResourceDataLoading;
  term: ReportResourceDataTerm;
}

export interface ReportResourceDataAll {
  accounts: any[];
  advertisers: any[];
  line_items: any[];
  campaigns: any[];
  creatives: any[];
  account: any[];
  deal_id: any[];
  layer_name: any[];
  ooh_layer_name: any[];
  ooh_distance: any[];
}

export interface ReportResourceDataHidden {
  date: boolean;
  format: boolean;
  run: boolean;
  scheduler: boolean;
}

export interface ReportResourceDataLoading {
  account: boolean;
  advertisers: boolean;
  campaigns: boolean;
  line_items: boolean;
  creatives: boolean;
  deal_id: boolean;
  layer_name: boolean;
  ooh_layer_name: boolean;
  ooh_distance: boolean;
  filters: boolean;
  report_type: boolean;
  dimensions: boolean;
  metrics: boolean;
}

export interface ReportResourceDataTerm {
  advertiser: string;
  campaign: string;
  line_item: string;
  creative: string;
  account: string;
  deal_id: string;
  layer_name: string;
  ooh_layer_name: string;
  ooh_distance: string;
}

export interface IReportFilterRule {
  data_range?: any;
  start_date?: any;
  end_date?: any;
  account?: any;
  advertisers?: any;
  campaigns?: any;
  line_items?: any;
  creatives?: any;
  deal_id?: any;
  layer_name?: any;
  ooh_layer_name?: any;
  ooh_distance?: any;
}
